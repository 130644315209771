const { TokenDashboard } = require("./TokenDashboard");

const isBetinDomain = window.location.hostname === "betin.network" || window.location.hostname === "betin90.network" ? true : false;

const TokenDashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    isBetinDomain && 
    {
      path: "dashboard",
      element: <TokenDashboard />,
    },
  ],
};

export default TokenDashboardConfig;
