import React, { useState, useEffect, useRef } from "react";
import jwtService from "src/app/auth/services/jwtService";
import { useDispatch, useSelector } from "react-redux";
import { getCountrylist } from "../../theme-layouts/shared-components/CountryListSlice";
import { Box, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faEnvelope, 
  faLock, 
  faUser, 
  faUserTag, 
  faUsers,
  faEye,
  faEyeSlash 
} from "@fortawesome/free-solid-svg-icons";
import { MobileLoginModal } from "./MobileLoginModal";
import {
  selectLoginModalVisible,
  selectMobileLoginOpen,
  selectMobileSignupOpen,
  selectSignupModalVisible,
  setLoginModalVisible,
  setForgotPassword,
  setMobileSignupOpen,
  setMoblieLoginOpen,
  setSignupModalVisible,
} from "src/app/main/store/uiSlice";
import { useScreenSm } from "src/app/hooks/useScreens";
import "react-phone-input-2/lib/style.css";
import "../../../styles/customPhoneCode.css";
import { SignupModal } from "./SignupModal";
import { MobileSignupModal } from "./MobileSignupModal";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM, ONLINE_SYSTEM } from "src/app/constants";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import PersonIcon from '@mui/icons-material/Person';

function LoginSignUpButtons() {
  const dispatch = useDispatch();
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [signupDisabled, setSignupDisabled] = useState(false);
  const systemType = useSystemType();
  const [showPassword, setShowPassword] = useState(false);

  const isSm = useScreenSm();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [bonusMode, setBonusMode] = useState("casino");
  const theme = useTheme();

  const signinRef = useRef(null);

  const [countryCodeList, setCuntryCodeList] = useState();
  const mobileLoginOpen = useSelector(selectMobileLoginOpen);
  const mobileSignupOpen = useSelector(selectMobileSignupOpen);

  const loginModalVisible = useSelector(selectLoginModalVisible);
  const signupModalVisible = useSelector(selectSignupModalVisible);
  const [resetEmail, setResetEmail] = useState("");
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const loginModalVisibleRef = useRef(loginModalVisible);
  loginModalVisibleRef.current = loginModalVisible;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (username === "" || password === "") setLoginDisabled(true);
    else setLoginDisabled(false);
  }, [username, password]);

  const loginToggle = () => {
    if (isSm) {
      dispatch(setLoginModalVisible(!loginModalVisible));
    } else {
      dispatch(setMoblieLoginOpen(true));
    }
  };

  const handleResetPassword = () => {
    dispatch(setForgotPassword({email: resetEmail})).then(() => {

    });
  }

  const openSignup = () => {
    if (systemType === BETSHOP_SYSTEM) return;

    dispatch(setLoginModalVisible(false));
    dispatch(setSignupModalVisible(!signupModalVisible));
  };

  const [forgotPassActive, setForgotPassActive] = useState(false);

  const openForgotPassword = () => {
    setForgotPassActive(!forgotPassActive);
  };

  useEffect(() => {
    dispatch(getCountrylist()).then((res) => {
      setCuntryCodeList(res.payload);
    });
  }, [dispatch]);

  const onPanelClick = (e) => {
    const signinBox = signinRef.current;
    if (!signinBox) return;
    if (!signinBox.contains(e.target) && loginModalVisibleRef.current) {
      dispatch(setLoginModalVisible(false));
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", onPanelClick);
    return () => window.removeEventListener("mousedown", onPanelClick);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform login action (e.g., dispatch a Redux action or make an API call)
    loginToggle();
    jwtService
      .signInWithUsernameAndPassword(username, password)
      .then(() => {
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((_errors) => {
        // Error handling
      });
  };

  return (
    <>
      <SignupModal visible={signupModalVisible} onClose={() => dispatch(setSignupModalVisible(false))} />
      <Box className="header_button" ref={signinRef}>
        <a
          onClick={loginToggle}
          style={{
            background: theme.palette.success.main
          }}
        >
          <img src="assets/images/login/login.svg" alt="login" className="w-[1em] h-[1em]" />
          {i18n.t('Login')}
        </a>
        <Box className={loginModalVisible ? "sign-in active" : "sign-in"}>
          <Box className="sign-in__content">
            <Box className={!forgotPassActive ? "login pb-20" : "login hide"}>
              <form className="login-form" onSubmit={handleLogin}>
                <Box className="login-form__field">
                  <Box className="form-input">
                    <FontAwesomeIcon icon={faUser} className="absolute left-[12px] top-[10px] !text-[24px]" />
                    <input
                      placeholder={i18n.t("Username")}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box className="login-form__field">
                  <Box className="relative form-input">
                    <FontAwesomeIcon icon={faLock} className="absolute left-[12px] top-[10px] !text-[24px]" />
                    <input
                      placeholder={i18n.t("Password")}
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="absolute right-[12px] top-[10px] !text-[24px] cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  </Box>
                </Box>
                <Box className="login-form__field">
                  <button className="login" onClick={handleLogin}>
                    <span>{i18n.t('Login')}</span>
                  </button>
                </Box>
              </form>
              <Box className="form__label">
                <a onClick={openForgotPassword}>{i18n.t('Forgot_your_password')}</a>
              </Box>
            </Box>
            <Box className={forgotPassActive ? "forgot_pass active" : "forgot_pass"}>
              <form className="login-form">
                <Box className="login-form__field">
                  <Box className="form-input">
                    <input 
                      placeholder={i18n.t("Please_enter_your_email_address")} 
                      type="email" 
                      value={resetEmail} 
                      onChange={(e) => setResetEmail(e.target.value)}
                    />
                    <i className="form-input__icon">
                      <i className="fa-regular fa-envelope"></i>
                    </i>
                  </Box>
                </Box>
                <Box className="login-form__field">
                  <button type="button" className="login" onClick={handleResetPassword}>
                    <span>{i18n.t('Submit')}</span>
                  </button>
                </Box>
              </form>
              <Box className="form__label">
                {i18n.t('Back_to')} ? <a onClick={openForgotPassword}>{i18n.t('Login')}</a>
              </Box>
              <Box className="sign-in__button">
                <a className="sign-in__link">{i18n.t('contact_us')}</a>
              </Box>
            </Box>
          </Box>
        </Box>
        {systemType === ONLINE_SYSTEM && (
          <a
            onClick={openSignup}
            className="!hidden sm:!flex"
            style={{
              backgroundColor: theme.palette.warning.main,
            }}
          >
            <img src="assets/images/login/signup.svg" alt="signup" className="w-[1em] h-[1em]" />
            {i18n.t('Sign_Up')}
          </a>
        )}
      </Box>

      <MobileLoginModal open={mobileLoginOpen} onClose={() => dispatch(setMoblieLoginOpen(false))} />
      <MobileSignupModal open={mobileSignupOpen} onClose={() => dispatch(setMobileSignupOpen(false))} />
    </>
  );
}

export default LoginSignUpButtons;