import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";
import { faEnvelope, faLock, faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForgotPassword, signin } from "src/app/main/store/uiSlice";
import JwtService from "src/app/auth/services/jwtService";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import i18n from "src/i18n";
import EmailIcon from '@mui/icons-material/Email';

export const MobileLoginModal = ({ open, onClose }) => {
  const [forgotPassActive, setForgotPassActive] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [resetEmail, setResetEmail] = useState("");

  useEffect(() => {
    if (username === "" || password === "") setLoginDisabled(true);
    else setLoginDisabled(false);
  }, [username, password]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const openSignup = () => {
    //
  };

  const openForgotPassword = () => {
    setForgotPassActive(!forgotPassActive);
  };

  const handleResetPassword = () => {
    dispatch(setForgotPassword({email: resetEmail})).then(() => {

    });
  }
  const handleLogin = (e) => {
    e.preventDefault();
    // Perform login action (e.g., dispatch a Redux action or make an API call)
    JwtService.signInWithUsernameAndPassword(username, password)
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
        onClose();
      })
      .catch((_errors) => {
        // toast.custom(<Alert severity="error" style={{ zIndex: 99999 }}>{_errors.response.data.user_error}</Alert>);
        // _errors.forEach((error) => {
        //   setError(error.type, {
        //     type: "manual",
        //     message: error.message,
        //   });
        // });
      });
  };

  return (
    <Modal open={open} onClose={onClose} className="fixed w-screen h-[calc(var(--app-height))] left-0 top-0" aria-label="MobileLoginModal">
      <Box className="relative w-full h-full bg-white">
        <Box className="absolute right-[12px] top-[12px] z-[100]">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="header_button w-full pt-[150px] px-[14px]" aria-label="modal-body">
          <Box className={!forgotPassActive ? "login" : "login hide"}>
            <form className="login-form">
              <Box className="login-form__field">
                <Box className="form-input">
                  <FontAwesomeIcon icon={faUser} className="absolute left-[12px] top-[13px] !text-[24px]" />
                  <input
                    placeholder={i18n.t("Username")}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Box>
              </Box>
              <Box className="login-form__field">
                <Box className="relative form-input">
                  <FontAwesomeIcon icon={faLock} className="absolute left-[12px] top-[13px] !text-[24px]" />
                  <input
                    placeholder={i18n.t("Password")}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute right-[12px] top-[13px] !text-[16px] cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </Box>
              </Box>
              <Box className="form__label mt-[12px] text-right">
                <a onClick={openForgotPassword}>{i18n.t('Forgot_your_password')}</a>
              </Box>
              <Box className="login-form__field">
                <button type="submit" className="login" disabled={loginDisabled} onClick={handleLogin}>
                  <span>{i18n.t('Login')}</span>
                </button>
              </Box>
            </form>
          </Box>
          <Box className={forgotPassActive ? "forgot_pass active" : "forgot_pass"}>
              <form className="login-form">
                <Box className="login-form__field">
                  <Box className="form-input relative">
                    <input 
                      placeholder={i18n.t("Please_enter_your_email_address")} 
                      type="email" 
                      value={resetEmail} 
                      onChange={(e) => setResetEmail(e.target.value)}
                    />
                    <EmailIcon className="absolute left-16 top-1/2 -translate-y-1/2" />
                  </Box>
                </Box>
                <Box className="login-form__field">
                  <button type="button" className="login" onClick={handleResetPassword}>
                    <span>{i18n.t('Submit')}</span>
                  </button>
                </Box>
              </form>
              <Box className="form__label mt-[12px]">
                {i18n.t('Back_to')} ? <a onClick={openForgotPassword}>{i18n.t('Login')}</a>
              </Box>
              <Box className="sign-in__button">
                <a className="sign-in__link">{i18n.t('contact_us')}</a>
              </Box>
            </Box>
        </Box>
      </Box>
    </Modal>
  );
};