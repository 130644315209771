import { Box, Stack, Typography, Button, Link } from "@mui/material";
import { useState } from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import { minHeight } from "@mui/system";
export const TokenDashboard = () => {
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  return (
    <Box
      className="bg-[#061041] min-h-screen flex flex-col" // Apply consistent dark background
      aria-label="Affiliate"
      style={{
        overflowY: "auto", // Enable vertical scrolling
        maxHeight: "calc(100vh - 100px)",
      }}
    >
      {/* Scrollable Content */}
      <Box
        style={{
          flex: 1, // Take up remaining space
        }}
      >
        <Box
          aria-label="content"
          className="relative z-1"
          style={{
            backgroundColor: "#061041", // Ensure background remains consistent
          }}
        >
          <Box className="lg:pl-[200px] px-[16px]">
            <Stack
              justifyContent="space-between"
              alignItems="flex-start"
              className="flex-col-reverse items-center lg:items-start lg:flex-row"
            >
              {/* Left Content */}
              <Box
                className="px-[20px] pt-[40px] lg:py-[120px]"
                style={{
                  flex: 6,
                  width: "100%", // Ensure it adapts to mobile
                }}
              >
                {/* Hero Section */}
                <Typography
                  className="text-white text-[20px] lg:text-[40px] xl:text-[50px] font-bold"
                  gutterBottom
                >
                  {i18next.t("The_Spectacular_Approach_for_Betting_Business")}
                  : Betin.Network
                </Typography>
                <Typography
                  className="text-[#42DFE3] text-[14px] md:text-[18px] xl:text-[20px] mt-[10px]"
                  gutterBottom
                >
                  {i18next.t(
                    "Join_us_for_a_decentralized,transparent,and_secure_betting_experience"
                  )}
                  .
                </Typography>
                <Box
                  className="flex flex-wrap gap-[10px] mt-[20px] justify-center md:justify-start"
                >
                  <Link
                    href="https://t.me/BetinNetwork"
                    target="_blank"
                    style={{ textDecoration: "none", background: "none" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        background:
                          "linear-gradient(98.97deg, #28F09C 15.05%, #00BE67 71.41%)",
                        color: "white",
                        padding: "8px 16px",
                      }}
                    >
                      {i18next.t("Join_Telegram")}
                    </Button>
                  </Link>

                  <Link
                    href="https://betin-network.gitbook.io/betin.network"
                    target="_blank"
                    style={{ textDecoration: "none", background: "none" }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#28F09C",
                        color: "#28F09C",
                        padding: "8px 16px",
                      }}
                    >
                      {i18next.t("Whitepaper")}
                    </Button>
                  </Link>

                  <Link
                    href="https://betin-network.gitbook.io/manifest.betin.network"
                    target="_blank"
                    style={{ textDecoration: "none", background: "none" }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: "#28F09C",
                        color: "#28F09C",
                        padding: "8px 16px",
                      }}
                    >
                      {i18next.t("Read_Betting_Web3_Manifest")}
                    </Button>
                  </Link>
                </Box>

                <Typography
                  className="text-white text-[18px] lg:text-[24px] mt-[40px] font-bold"
                  gutterBottom
                >
                  {i18next.t("Exclusive_Token_Sale_Opportunity")}
                </Typography>

                <Typography
                  className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                  gutterBottom
                  sx={{ lineHeight: 1.8 }} // Adjusted line height for better readability
                >
                  {i18next.t(
                    "At_Betin.Network,we_are_offering_an_exclusive_token_sale_opportunity_to_a_limited_number_of_investors"
                  )}
                  .<br />
                  {i18next.t(
                    "The_first_20%_of_the_token_supply_is_available_exclusively_through_the_private_sale"
                  )}
                  .{" "}
                  {i18next.t(
                    "By_participating_in_this_special_sale,you_can_join_the_project_early_and_leverage_the_advantages"
                  )}
                  . <br />
                  <b className="text-[16px] text-white">
                    {i18next.t(
                      "You_should_know_that_the_opportunity_you_have_come_across_is_not_just_an_investment_but_also_a_strategic_initiative_with_strong_growth_potential"
                    )}
                    .
                  </b>
                </Typography>
                <Typography
                  className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                  gutterBottom
                  sx={{
                    paddingLeft: "10px", // Indent the list for better alignment
                    lineHeight: 1.8, // Consistent line height
                  }}
                >
                  <b>{i18next.t("Token_Sale_Details")}:</b> <br />·{" "}
                  <b>{i18next.t("Private_Sale_Supply")}:</b> 200,000,000 BETIN
                  (20%) <br />·{" "}
                  <b>
                    {i18next.t("Softcap")} {i18next.t("Minimum_Target")}:
                  </b>{" "}
                  TBA <br />·{" "}
                  <b>
                    {i18next.t("Hardcap")} {i18next.t("Maximum_Target")}:
                  </b>{" "}
                  TBA <br />· <b>{i18next.t("Sale_Price")}:</b> TBA <br />·{" "}
                  <b>{i18next.t("Start_and_End_Date")}:</b> TBA <br />
                </Typography>
                <Stack>
                  <Typography
                    className="text-white text-[18px] lg:text-[24px] mt-[40px] font-bold"
                    gutterBottom
                  >
                    🚨 {i18next.t("Token_Generation_Event")}
                  </Typography>

                  <Typography
                    className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                    gutterBottom
                    sx={{ lineHeight: 1.8 }}
                  >
                    {i18next.t(
                      "Our_Token_Generation_Event(TGE)_has_not_yet_occurred"
                    )}
                    .
                    {i18next.t(
                      "There_are_no_project_tokens_or_liquidity_pools(LP)_under_the_name_$BTN_or_$BETIN_on_any_network"
                    )}
                    .
                  </Typography>

                  <Typography
                    className="text-white text-[18px] lg:text-[24px] mt-[40px] font-bold"
                    gutterBottom
                  >
                    🚨 {i18next.t("Legal_Disclaimer")}
                  </Typography>

                  <Typography
                    className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                    gutterBottom
                    sx={{ lineHeight: 1.8 }}
                  >
                    {i18next.t(
                      "The_Web3_Betting_Platform_is_currently_INACTIVE,_and_the_membership_system_is_NOT_OPERATIONAL"
                    )}.
                  </Typography>
                  <Typography
                    className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                    gutterBottom
                    sx={{ lineHeight: 1.8 }}
                  >
                    {i18next.t(
                      "We_are_planning_to_obtain_the_necessary_TOKEN-BASED_ONLINE_GAMING_LICENCE_to_ensure_our_platform_operates_with_the_highest_levels_of_security_and_legal_compliance"
                    )}.
                  </Typography>
                  <Typography
                    className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
                    gutterBottom
                    sx={{ lineHeight: 1.8 }}
                  >
                    {i18next.t(
                      "Once_the_mainnet_process_is_completed,_our_system_will_be_fully_launched,_and_we_will_begin_providing_uninterrupted_services_to_our_valued_users"
                    )}.
                  </Typography>
                </Stack>
              </Box>

              {/* Right Image */}
              <Stack className="sm:w-[500px] lg:w-[978px] xs:w-[370px] relative md:sticky top-0">
                <Box className="w-full h-[370px] overflow-hidden xs:h-[370px]  sm:h-[500px] lg:h-[895px] flex lg:block">
                  <Box className="relative w-[978px] h-[795px] min-w-[978px] min-h-[795px] scale-[0.3] xs:scale-[0.4] sm:scale-50 lg:scale-100 origin-top-left">
                    <img
                      src="assets/images/affiliate/content/shine-down-planet.png"
                      alt="content-image"
                      className="absolute"
                    />
                    <img
                      src="assets/images/affiliate/content/line1.png"
                      alt="content-image"
                      className="screen-line1 absolute w-[654px] left-[123px] bottom-[187px]"
                    />
                    <img
                      src="assets/images/affiliate/content/line2.png"
                      alt="content-image"
                      className="screen-line2 absolute w-[542px] left-[185px] bottom-[194px]"
                    />
                    <img
                      src="assets/images/affiliate/content/line3.png"
                      alt="content-image"
                      className="screen-line3 absolute w-[591px] left-[174px] bottom-[102px]"
                    />
                    <img
                      src="assets/images/affiliate/content/planet-light.png"
                      alt="content-image"
                      className="screen-light absolute w-[551px] left-[198px] bottom-[125px]"
                    />
                    <img
                      src="assets/images/affiliate/content/shine-dots.png"
                      alt="content-image"
                      className="screen-shine absolute w-[578px] left-[183px] bottom-[152px]"
                    />
                    <img
                      src="assets/images/affiliate/content/planet.png"
                      alt="content-image"
                      className="absolute w-[850px] left-[19px] top-[68px]"
                    />
                    <img
                      src="assets/images/affiliate/content/shine-dots-decor-small.png"
                      alt="content-image"
                      className="absolute screen-shine-small w-[694px] left-[113px] bottom-[184px]"
                    />
                    <img
                      src="assets/images/affiliate/content/stones-fly.png"
                      alt="content-image"
                      className="absolute screen-stones w-[841px] left-[91px] bottom-[64px]"
                    />
                    <img
                      src="assets/images/affiliate/content/ball-chip.png"
                      alt="content-image"
                      className="absolute screen-ball-chip w-[600px] left-[151px] bottom-[218px]"
                    />
                    <img
                      src="assets/images/affiliate/content/box.png"
                      alt="content-image"
                      className="absolute screen-box w-[381px] left-[205px] bottom-[91px]"
                    />
                  </Box>

                  {/* <Box
                    className="absolute flex justify-center w-full"
                    style={{
                      zIndex: 200,
                      display: "flex",
                      visibility: "visible", 
                      opacity: 1,
                      bottom: 0
                    }}
                  >
                    <Link
                      href="https://form.jotform.com/243584913117055"
                      target="_blank"
                      style={{ textDecoration: "none", background: "none" }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          background:
                            "linear-gradient(98.97deg, #28F09C 15.05%, #00BE67 71.41%)",
                          color: "white",
                          padding: "8px 16px",
                        }}
                      >
                        {i18next.t("Visit_the_Form")}
                      </Button>
                    </Link>
                  </Box> */}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box className="mb-[5%]">
        <FooterLayout1 />
      </Box>
    </Box>
  );
};
