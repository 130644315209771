import {
  Box,
  Collapse,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HorizontalScroll } from "./HorizontalScroll";
import { useModal } from "../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCashierModalTab,
  setCashierModalTab,
  getBonusList,
  getPaymentURL,
  getWithdraw,
  getBankDepositPaymentURL,
  getDepositPaparaURL,
  getDepositMaksiURL,
  getDepositJetpayURL,
  getWithdrawPapara,
  getWithdrawPayfix,
  getPaymentGateways,
  getWithdrawalCheck,
  getDepositURL
} from "../main/store/uiSlice";
import { selectUser } from "app/store/userSlice";
import BankTransferForm from "./BankTransferForm";
import JetpayForm from "./JetpayForm";
import PaparaForm from "./PaparaForm";
import PayfixForm from "./PayfixForm";
import { bonusItemsData } from "./LoginSignUpButtons/BonusItem";
import { useEffect } from "react";
import { Bonus } from "../main/settings/outlets/Bonus/bonus";
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { AnimatePresence, motion } from "framer-motion";
import CancelIcon from '@mui/icons-material/Cancel';
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import BonusItem from "./BonusItem";
import IFrameModal from "./IFrameModal";

const buttons = [
  { id: "all", label: "All" },
  { id: "recommeneded", label: "Recommended" },
  { id: "bank-transfer", label: "Bank_Transfer" },
  // { id: "credit-card", label: "Credit Card" },
  { id: "cryptocurrency", label: "Cryptocurrency" },
];

const methods = [
  {
    id: "bank-transfer",
    title: "Bank_Transfers",
    items: [
      // {
      //   id: "bank-transfer",
      //   title: "Bank Transfer",
      //   logo: "assets/images/payments/bank.svg",
      // },
      // {
      //   id: "papara",
      //   title: "Papara",
      //   logo: "assets/images/payments/paparamaksi-new.png",
      // },
      // {
      //   id: "payfix",
      //   title: "Payfix",
      //   logo: "assets/images/payments/payfixmaksi-new.png",
      // },
      // {
      //   id: "pep",
      //   title: "Pep",
      //   logo: "assets/images/payments/pepmaksi-new.png",
      // },
      // {
      //   id: "paybol",
      //   title: "paybol",
      //   logo: "assets/images/payments/paybolmaksi-new.png",
      // },
      {
        id: "fastHavale",
        title: "FastHavale",
        logo: "assets/images/payments/fasthavaledark.svg",
      },
      {
        id: "havale",
        title: "Havale",
        logo: "assets/images/payments/havale.svg",
      },
      {
        id: "krediKarti",
        title: "KrediKarti",
        logo: "assets/images/payments/kkdark.svg",
      },
      {
        id: "mefete",
        title: "Mefete",
        logo: "assets/images/payments/metefedark.svg",
      },
      {
        id: "papara",
        title: "Papara",
        logo: "assets/images/payments/papara.svg",
      },
      {
        id: "parazula",
        title: "Parazula",
        logo: "assets/images/payments/parazuladark.svg",
      },
      {
        id: "payco",
        title: "Payco",
        logo: "assets/images/payments/paycdark.svg",
      },
      {
        id: "payfix",
        title: "Payfix",
        logo: "assets/images/payments/payfix.svg",
      },
      {
        id: "popy",
        title: "Popy",
        logo: "assets/images/payments/popydark.svg",
      },
      {
        id: "qr",
        title: "QR",
        logo: "assets/images/payments/qrdark.svg",
      },
      // {
      //   id: "jetpay",
      //   title: "jetpay",
      //   logo: "assets/images/payments/jetpay.png",
      // },
      // { id: "rapid-transfer", title: "Rapid Transfer", logo: "assets/images/payments/rapid.svg" },
    ],
  },
  {
    id: "recommeneded",
    title: 'Recommended',
    items: [
      // {
      //   id: "papara",
      //   title: "Papara",
      //   logo: "assets/images/payments/paparamaksi-new.png",
      // },
      {
        id: "havale",
        title: "Havale",
        logo: "assets/images/payments/havale.svg",
      },
      {
        id: "qr",
        title: "QR",
        logo: "assets/images/payments/qrdark.svg",
      },
    ]
  },
  // {
  //   id: "credit-card",
  //   title: "Credit card",
  //   items: [
  //     { id: "credit-card", title: "Credit Card", logo: "assets/images/payments/creditcard.svg" },
  //     { id: "revolut", title: "Revolut", logo: "assets/images/payments/revolut.svg" },
  //     { id: "bp-wallet", title: "BP Walle", logo: "assets/images/payments/bpwallet.svg" },
  //     { id: "webpayz", title: "WebPayz Credit Card", logo: "assets/images/payments/webpayzcreditcards.svg" },
  //   ],
  // },
  {
    id: "cryptocurrency",
    title: "Cryptocurrency",
    items: [
      {
        id: "USDT.TRC20",
        title: "Tether USD (Tron/TRC20)",
        logo: "https://www.coinpayments.net/images/coins/chained/USDT.TRX.png",
      },
      {
        id: "USDT.ERC20",
        title: "Tether USD (ERC20)",
        logo: "https://www.coinpayments.net/images/coins/chained/USDT.ETH.png",
      },
      {
        id: "USDT.SOL",
        title: "Tether USD (Solana)",
        logo: "https://www.coinpayments.net/images/coins/USDT.png",
      },
      {
        id: "BTC",
        title: "Bitcoin BTC",
        logo: "https://www.coinpayments.net/images/coins/BTC.png",
      },
      {
        id: "ETH",
        title: "Ether",
        logo: "https://www.coinpayments.net/images/coins/ETH.png",
      },
      {
        id: "DOGE",
        title: "Dogecoin",
        logo: "https://www.coinpayments.net/images/coins/DOGE.png",
      },
      {
        id: "LTC",
        title: "Litecoin",
        logo: "https://www.coinpayments.net/images/coins/LTC.png",
      },
      {
        id: "SOL",
        title: "Solana",
        logo: "https://www.coinpayments.net/images/coins/SOL.png",
      },
      // {
      //   id: "BTC.BEP2",
      //   title: "Bitcoin/BTCB Token (BC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/BTC.BNB.png",
      // },
      // {
      //   id: "BTC.LN",
      //   title: "Bitcoin (Lightning Network)",
      //   logo: "https://www.coinpayments.net/images/coins/BTCLN.png",
      // },
      // {
      //   id: "BCH",
      //   title: "Bitcoin Cash",
      //   logo: "https://www.coinpayments.net/images/coins/BCH.png",
      // },
      // {
      //   id: "BNB.BSC",
      //   title: "BNB Coin (BSC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/BNB.png",
      // },
      // {
      //   id: "ETC",
      //   title: "Ether Classic",
      //   logo: "https://www.coinpayments.net/images/coins/ETC.png",
      // },
      // {
      //   id: "FIRO",
      //   title: "Firo",
      //   logo: "https://www.coinpayments.net/images/coins/FIRO.png",
      // },
      // {
      //   id: "USDC.SOL",
      //   title: "USD Coin (Solana)",
      //   logo: "https://www.coinpayments.net/images/coins/USDC.png",
      // },
      // {
      //   id: "FTN",
      //   title: "Fasttoken (ERC20)",
      //   logo: "https://www.coinpayments.net/images/coins/FTN.png",
      // },
      // {
      //   id: "FTN.BAHAMUT",
      //   title: "Fasttoken (Bahamut)",
      //   logo: "https://www.coinpayments.net/images/coins/FTN.png",
      // },
      // {
      //   id: "SHIB",
      //   title: "SHIBA INU (ERC20)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/SHIB.ETH.png",
      // },
      // {
      //   id: "SHIB.BEP20",
      //   title: "SHIBA INU (BSC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/SHIB.BNBBSC.png",
      // },
      // {
      //   id: "TRX",
      //   title: "TRON",
      //   logo: "https://www.coinpayments.net/images/coins/TRX.png",
      // },
      // {
      //   id: "USDC",
      //   title: "USD Coin (ERC20)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/USDC.ETH.png",
      // },
      // {
      //   id: "USDC.BEP20",
      //   title: "USD Coin (BSC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/USDC.BNBBSC.png",
      // },
      // {
      //   id: "USDT.BEP2",
      //   title: "Tether USD (BC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/USDT.BNB.png",
      // },
      // {
      //   id: "USDT.BEP20",
      //   title: "Tether USD (BSC Chain)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/USDT.BNBBSC.png",
      // },
      // {
      //   id: "USDT.PRC20",
      //   title: "Tether USD (Polygon/MATIC)",
      //   logo: "https://www.coinpayments.net/images/coins/chained/USDT.MATICPOLY.png",
      // },
      // {
      //   id: "XEM",
      //   title: "NEM",
      //   logo: "https://www.coinpayments.net/images/coins/XEM.png",
      // },
      // {
      //   id: "XMR",
      //   title: "Monero",
      //   logo: "https://www.coinpayments.net/images/coins/XMR.png",
      // },
      // {
      //   id: "XVG",
      //   title: "VERGE",
      //   logo: "https://www.coinpayments.net/images/coins/XVG.png",
      // },
      // {
      //   id: "ZEC",
      //   title: "ZCash",
      //   logo: "https://www.coinpayments.net/images/coins/ZEC.png",
      // },
    ],
  },
];

export const MethodGroup = ({ id, title, items, handleSelectCoin, limits, user, amount, address, handleAmount, cashierModalTab, maksiParaMethod, handleAddress }) => {
  const [menuVisible, setMenuVisible] = useState(true);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const limitMapping = {
    cryptocurrency: 'CoinPayment',
    'bank-transfer': 'Maxipara',
    papara: 'Maxipara',
    payfix: 'Maxipara',
    pep: 'Maxipara',
    paybol: 'Maxipara',
    // jetpay: 'Jetpay'
  };

  const handlePayment = (item, limits) => {
    handleSelectCoin(item, limits);
  };

  return (
    <Box className="bg-[#1A3769] rounded-[8px]">
      <button
        className="flex justify-between items-center px-[16px] py-[8px] w-full"
        onClick={() => setMenuVisible((v) => !v)}
      >
        <Typography>{i18next.t(title)}</Typography>
        {menuVisible && <KeyboardArrowUpIcon />}
        {!menuVisible && <KeyboardArrowDownIcon />}
      </button>
      <Collapse in={menuVisible}>
        <Box className="grid grid-cols-5 gap-[10px] p-[16px]">
          <AnimatePresence>
            {items.map((item) => {
              let limitKey = limitMapping[item.id];
              if (limitKey == "" || limitKey == undefined) {
                limitKey = "CoinPayment"
              }
              const methodLimits = limits[limitKey] || {};
              let depositLimits = methodLimits.Deposit?.[user?.data.currency?.toLowerCase()] || {};
              if (cashierModalTab === "withdrawal")
                depositLimits = methodLimits.Withdraw?.[user?.data.currency?.toLowerCase()] || {};
              return (
                <motion.button
                  onClick={() => handlePayment(item, depositLimits)}
                  key={item.id}
                  className="flex flex-col items-center w-full"
                  layout
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.1 }}
                >
                  <Box className="bg-white w-full h-[66px] p-[12px] rounded-[6px]">
                    <img
                      src={item.logo}
                      className="object-contain w-full h-full transition-all duration-150 hover:scale-105"
                    />
                  </Box>
                  <Typography className="text-[11px]">
                    {i18next.t(item.title)}
                  </Typography>
                  {/* {depositLimits.min && depositLimits.max && (
                    <Typography className="text-[9px] text-white-600">
                      Min: {depositLimits.min} | Max: {depositLimits.max}
                    </Typography>
                  )} */}
                </motion.button>
              )
            })}
          </AnimatePresence>
        </Box>
        {/* {id == 'cryptocurrency' && cashierModalTab === "deposit" && <Stack gap="8px" className="px-[12px] py-[4px]">
          <Typography className="flex">
            {" "}
            {i18next.t("Amount")} ({user.data.currency}):{" "}
          </Typography>
          <input
            className="border text-gray-800 py-[12px] px-[24px] rounded-full"
            value={amount}
            onChange={(e) => handleAmount(e.target.value)}
          />
        </Stack>} */}
        {/* {((maksiParaMethod != "jetpay" && maksiParaMethod != "bank-transfer") || id == "cryptocurrency") && cashierModalTab === "withdrawal" && (
          <Stack gap="8px" className="px-[12px] py-[4px]">
            <Typography className="flex">
              {" "}
              {i18next.t("Address")}
            </Typography>
            <input
              className="border text-gray-800 py-[12px] px-[24px] rounded-full"
              value={address}
              onChange={(e) => handleAddress(e.target.value)}
            />

            <Typography className="flex">
              {" "}
              {i18next.t("Amount")}{" "}
            </Typography>
            <input
              className="border text-gray-800 py-[12px] px-[24px] rounded-full"
              value={amount}
              onChange={(e) => handleAmount(e.target.value)}
            />
          </Stack>
        )} */}

        {/* <div className="bg-[#ffffff] mt-[25px]">
          {id != 'cryptocurrency' && maksiParaMethod === "bank-transfer" && cashierModalTab === "withdrawal" && <BankTransferForm className="px-[12px] py-[4px]" />}
          {id != 'cryptocurrency' && maksiParaMethod === "jetpay" && cashierModalTab === "withdrawal" && <JetpayForm className="px-[12px] py-[4px]" />}
        </div> */}
      </Collapse>
    </Box>
  );
};

export const CashierModal = ({ visible, onClose }) => {
  const cashierModalTab = useSelector(getCashierModalTab);
  const dispatch = useDispatch();
  const [group, setGroup] = useState("all");
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const user = useSelector(selectUser);
  const [maksiParaMethod, setMaksiParaMethod] = useState("");
  const [jetpayMethod, setJetpayMethod] = useState("");
  const [bonusMode, setBonusMode] = useState(0);
  const [bonusList, setBonusList] = useState([]);
  const [showBonus, setShowBonus] = useState(false);
  const [limits, setLimits] = useState({});
  const [loading, setLoading] = useState(true);
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [cashierModalVisible, setCashierModalVisible] = useState(true);
  const [depositMethod, setDepositMethod] = useState('');
  const [depositDialogLimits, setDepositDialogLimits] = useState(0);
  const [withdrawlCheck, setWithdrawlCheck] = useState();
  const [selectedWithdrawalMethod, setSelectedWithdrawalMethod] = useState('');
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState('');
  const [iFrameModalOpen, setIFrameModalOpen] = useState(false);


  useEffect(() => {
    setLoading(true);
    setCashierModalVisible(true)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [visible])

  useEffect(() => {
    if (user?.data.currency != null)
      dispatch(getBonusList({ trigger: "deposit" })).then((res) => {
        const data = res.payload;
        let bonusData = data.bonus_list.map((d) => {
          return {
            id: d.id,
            title: d.name,
            subtitle: "",
          };
        });
        bonusData = [
          {
            id: -1,
            title: "No Bonus",
            subtitle: "",
          },
          ...bonusData,
        ];
        setBonusList(bonusData);
      });
  }, [user])

  useEffect(() => {
    dispatch(getPaymentGateways()).then((res) => {
      const data = res.payload;
      const parsedLimits = {};
      data.gateway_list.forEach((item) => {
        if (item.threshold_amount) {
          parsedLimits[item.gateway] = JSON.parse(item.threshold_amount);
        }
      });
      setLimits(parsedLimits);
    });
  }, []);

  const setTab = (tab) => {
    if (tab == "withdrawal") {
      setLoading(true)
      dispatch(getWithdrawalCheck()).then((res) => {
        const data = res.payload;
        setWithdrawlCheck(data);
        setTimeout(() => {
          setLoading(false);
        }, 1000)
        dispatch(setCashierModalTab(tab));
      });
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false);
      }, 1000)
      dispatch(setCashierModalTab(tab));
    }
  };

  const handleSelectCoin = (item, limits) => {
    if (item.id == "jetpay") {
      dispatch(getDepositJetpayURL({ bonusMode })).then((res) => {
        const data = res.payload;
        if (data.url != undefined) {
          window.open(data.url);
        }
      });
    } else if (
      item.id == "bank-transfer" ||
      // item.id == "papara" ||
      // item.id == "payfix" ||
      item.id == "pep" ||
      item.id == "paybol"
    ) {
      dispatch(getDepositMaksiURL({ gateway: item.id, bonusMode })).then(
        (res) => {
          const data = res.payload;
          if (data.url != undefined) {
            window.open(data.url);
          }
        }
      );
    } else {
      dispatch(getDepositURL({ gateway: item.id, bonusMode })).then(
        (res) => {
          const data = res.payload;
          if (data.url != undefined) {
            window.open(data.url);
          }
        }
      );

      setDepositModalOpen(true);
      setDepositMethod(item);
      setDepositDialogLimits(limits);
      setCashierModalVisible(false);
    }
    // dispatch(getPaymentURL({ coin: item.id, amount, bonusMode })).then(
    //   (res) => {
    //     const data = res.payload;
    //     window.open(data.result.checkout_url);
    //   }
    // );
  };

  const handleMaksiparaWithdraw = (item, limits) => {
    setMaksiParaMethod(item.id);
    setSelectedWithdrawalMethod(item);
    setWithdrawModalOpen(true);
    setCashierModalVisible(false);

    setDepositDialogLimits(limits);

  };

  const handleWithdraw = (item, limits) => {
    setWithdrawModalOpen(true);
    setCashierModalVisible(false);
    setSelectedWithdrawalMethod(item);
    setDepositDialogLimits(limits);
  };

  useModal(visible);

  return (
    <>
      {cashierModalVisible && (
        <>
          <Box
            className="fixed w-screen h-[calc(var(--app-height))] left-0 top-0 invisible data-[visible=true]:visible opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 z-[9997] bg-[#ffffff10]"
            data-visible={visible}
            onClick={onClose}
            sx={{ backdropFilter: "blur(4px)" }}
          />
          <Box
            className="hidden data-[visible=true]:block opacity-0 data-[visible=true]:opacity-100 transition-all duration-150 w-full max-w-[720px] z-[9998] bg-[#0d1d34] sm:p-[24px] p-[16px] sm:rounded-[8px] shadow-lg text-white absolute left-1/2 -translate-x-1/2 top-0 sm:top-[10px] sm:max-h-[98vh] overflow-hidden"
            data-visible={visible}
          >
            <button
              className="absolute top-[8px] right-[16px] w-[16px] h-[16px]"
              onClick={onClose}
            >
              <CloseIcon className="text-white min-w-[16px] min-h-[16px]" />
            </button>
            <Box className="flex flex-col gap-[12px] sm:h-auto sm:min-h-[300px] h-[96vh]">
              <Typography className="text-[24px] font-bold text-center">
                {i18next.t("Cashier")}
              </Typography>
              <Box className="flex rounded-[8px] overflow-hidden bg-[#1a3769] min-h-[45px] mt-[12px]">
                <button
                  className="flex justify-center items-center py-[8px] hover:bg-[#456397] data-[selected=true]:bg-[#4DA533] data-[selected=true]:text-white w-full"
                  data-selected={cashierModalTab === "deposit"}
                  onClick={() => setTab("deposit")}
                >
                  <Typography>{i18next.t("Deposit")}</Typography>
                </button>
                <button
                  className="flex justify-center items-center py-[8px] hover:bg-[#456397] data-[selected=true]:bg-[#4DA533] w-full"
                  data-selected={cashierModalTab === "withdrawal"}
                  onClick={() => setTab("withdrawal")}
                >
                  <Typography>{i18next.t("Withdrawal")}</Typography>
                </button>
              </Box>
              {cashierModalTab === "deposit" && (
                loading ? (
                  <Box className="loader-line mt-[60px] absolute top-1/2 left-1/2" />
                ) : (
                  <>
                    <Box className="p-[12px] w-full bg-[#1A3769] rounded-[8px] h-auto">
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked
                            sx={{
                              ".MuiSwitch-track": {
                                backgroundColor: "#ffffff80 !important",
                              },
                              ".Mui-checked+.MuiSwitch-track": {
                                backgroundColor: "#4DA533 !important",
                                color: "#4DA533 !important"
                              },
                              ".Mui-checked": {
                                color: "#4DA533 !important"
                              }
                            }}
                            checked={showBonus}
                            onClick={(e) => {
                              setShowBonus(e.target.checked);
                            }}
                          />
                        }
                        label={i18next.t("My_Bonuses")}
                      />
                      <HorizontalScroll>
                        <Box className="flex gap-[4px] w-full">
                          {showBonus &&
                            bonusList.map((b, index) => (
                              <BonusItem
                                key={index}
                                item={b}
                                bonusMode={bonusMode}
                                onClick={() => {
                                  setBonusMode(b.id);
                                }}
                              />
                            ))}
                        </Box>
                      </HorizontalScroll>
                    </Box>
                    <Stack
                      className="sm:px-[36px] gap-[12px]"
                    >
                      <Typography
                        className="sm:text-[18px] text-[16px] sm:py-[8px] text-center"
                      >
                        {i18next.t("Payment_method")}
                      </Typography>
                      <HorizontalScroll>
                        <Box className="flex gap-[4px] sm:justify-center w-full">
                          {buttons.map((b) => (
                            <button
                              key={b.id}
                              className="data-[selected=true]:bg-[#4DA533] hover:bg-[#4DA533] text-[#ddd] data-[selected=true]:text-white rounded-[5px] bg-[#3D4A5D] px-[8px] sm:px-[12px] sm:py-[6px] transition-all duration-300"
                              data-selected={b.id === group}
                              onClick={() => setGroup(b.id)}
                              sx={{
                                "&:hover": {
                                  backgroundColor:
                                    b.id === group ? "#4DA533" : "#324A5F",
                                },
                              }}
                            >
                              {i18next.t(b.label)}
                            </button>
                          ))}
                        </Box>
                      </HorizontalScroll>
                      <Box className="flex flex-col gap-[9px] max-h-[calc(var(--app-height)-300px)] overflow-y-auto mt-[12px] pb-[30%] sm:pb-[25%]">
                        {methods
                          .filter((m) => group === "all" || m.id === group)
                          .map((method) => (
                            <Stack
                              key={method.id}
                              gap="16px"
                            >
                              <MethodGroup
                                key={method.id}
                                {...method}
                                handleSelectCoin={handleSelectCoin}
                                limits={limits}
                                user={user}
                                amount={amount}
                                address={address}
                                handleAmount={(v) => setAmount(v)}
                                handleAddress={(v) => setAddress(v)}
                                cashierModalTab={cashierModalTab}
                                maksiParaMethod={maksiParaMethod}
                              />
                            </Stack>
                          ))}
                      </Box>
                    </Stack>
                  </>
                )
              )}
              {cashierModalTab === "withdrawal" && (
                loading ? (
                  <Box className="loader-line mt-[60px] top-1/2 left-1/2" />
                ) : (
                  <Box className="min-h-[300px] justify-center h-full">
                    {(withdrawlCheck?.account_balance > 0 && !withdrawlCheck?.active_sport_bonus && !withdrawlCheck?.active_casino_bonus ? <Box className="flex flex-col gap-[9px] h-full overflow-y-auto justify-between">
                      <Box className="flex flex-col gap-[9px] max-h-[calc(var(--app-height)-150px)] overflow-y-auto pb-[5%]">
                        {methods
                          .filter(
                            (m) =>
                              m.id === "cryptocurrency" || m.id === "bank-transfer"
                          )
                          .map((method) => {
                            if (method.id === "cryptocurrency")
                              return (
                                <Stack gap="16px">
                                  <MethodGroup
                                    key={method.id}
                                    {...method}
                                    handleSelectCoin={handleWithdraw}
                                    limits={limits}
                                    user={user}
                                    amount={amount}
                                    address={address}
                                    handleAmount={(v) => setAmount(v)}
                                    handleAddress={(v) => setAddress(v)}
                                    cashierModalTab={cashierModalTab}
                                    maksiParaMethod={maksiParaMethod}
                                  />
                                </Stack>
                              );
                            else
                              return (
                                <MethodGroup
                                  key={method.id}
                                  {...method}
                                  handleSelectCoin={handleMaksiparaWithdraw}
                                  limits={limits}
                                  user={user}
                                  amount={amount}
                                  address={address}
                                  handleAmount={(v) => setAmount(v)}
                                  handleAddress={(v) => setAddress(v)}
                                  cashierModalTab={cashierModalTab}
                                  maksiParaMethod={maksiParaMethod}
                                />
                              );
                          })}
                      </Box>
                    </Box> : <Stack className="min-h-[300px] justify-center items-center rounded-[8px] bg-[#1A3769]">
                      <CancelIcon className="w-[100px] h-[100px] gap-[60px] text-[#FF7C7A]" />
                      <Typography className="text-[16px] mt-[20px]">
                        {i18next.t('Deposit_prior_to_withdrawal_is_required')}
                      </Typography>
                    </Stack>)}
                    {/* <Box className="bg-[#ffffff] mt-[25px]">
                      {maksiParaMethod === "bank-transfer" && <BankTransferForm />}
                      {maksiParaMethod === "jetpay" && <JetpayForm />}
                    </Box> */}
                  </Box>
                )
              )}
            </Box>
          </Box>
        </>
      )}
      <DepositModal
        open={depositModalOpen}
        depositMethod={depositMethod}
        depositDialogLimits={depositDialogLimits}
        handleBack={() => {
          setCashierModalVisible(true);
          setDepositModalOpen(false);
        }}
        currency={user?.data.currency}
        bonusMode={bonusMode}
      />

      <WithdrawModal
        open={withdrawModalOpen}
        withdrawMethod={selectedWithdrawalMethod}
        withdrawLimits={depositDialogLimits}
        handleBack={() => {
          setCashierModalVisible(true);
          setWithdrawModalOpen(false);
        }}
        currency={user?.data.currency}
        bonusMode={bonusMode}
      />

      <IFrameModal
        open={iFrameModalOpen}
        handleBack={() => {
          setCashierModalVisible(true);
          setIFrameModalOpen(false);
        }}
        iFrameUrl={iFrameUrl}
      />

    </>
  );
};
