import { CustomImage } from "app/shared-components/CustomImage";
import { Link } from "react-router-dom";

const { Box, Typography } = require("@mui/material");

export const PromotionCategoryLink = ({ title, icon, selected, path }) => {
  return (
    <Link
      aria-label="PromotionCategoryLink"
      className="h-[38px] px-[10px] flex items-center gap-[4px] bg-[#172531] data-[selected=true]:bg-[#4ea533] rounded-[4px] whitespace-nowrap min-w-fit"
      data-selected={selected}
      to={path}
    >
      <img src={icon} alt="" className="w-[18px] h-[18px] object-cover" />
      <Typography className="text-white uppercase">{title}</Typography>
    </Link>
  );
};
