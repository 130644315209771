import "../../../../styles/customPhoneCode.css";
import { Alert, Box, Typography } from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  updateProfile,
  updatePassword,
} from "../store/profileSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import i18n from "src/i18n";
import toast from "react-hot-toast";

const options = countryList().getData();

const InputGroup = ({ label, inputProps }) => {
  return (
    <Box className="w-full flex flex-col md:flex-row items-start md:items-center  -[8px] mt-[5px]">
      <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">
        {label}
      </Typography>
      <input
        className="border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[12px] py-[8px] bg-[#EDF0F2] text-[#5E5A51] w-full"
        {...inputProps}
      />
    </Box>
  );
};

const Hr = () => (
  <Box className="w-full h-[1.5px] border-t-[1.5px] border-t-[#D9E1E5]"></Box>
);

export const Profile = () => {
  const genderOptions = [
    { value: "male", label: i18next.t("Male") },
    { value: "female", label: i18next.t("Female") },
  ];

  const dispatch = useDispatch();
  const isMd = useScreenMd();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    dob: null,
    email: "",
    country: "",
    gender: "",
    userName: "",
    city: "",
    idNumber: "",
    street: "",
    houseNumber: "",
    zipCode: "",
    currentPassword: "",
    newPassword: "",
  });
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [enableEdit, setEnableEdit] = useState(true);

  useEffect(() => {
    dispatch(getProfile()).then((res) => {
      const data = res.payload;
      if (data.id_number) {
        setEnableEdit(false);
      }
      setFormData({
        firstName: data.firstname || "",
        lastName: data.lastname || "",
        phone: data.phone || "",
        dob: data.birthday ? new Date(data.birthday) : null,
        email: data.email || "",
        country: data.country || "",
        gender: data.gender || "",
        userName: data.username || "",
        city: data.city || "",
        idNumber: data.id_number || "",
        street: data.address || "",
        houseNumber: data.house_number || "",
        zipCode: data.post_zip || "",
        currentPassword: "",
        newPassword: "",
      });
    });
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (
      !formData.idNumber ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.dob ||
      !formData.country ||
      !formData.zipCode ||
      !formData.street ||
      !formData.city ||
      !formData.houseNumber ||
      !formData.gender
    ) {
      toast.custom(
        <Alert severity="error">Please make sure you input all data.</Alert>
      );
      return;
    }
    const submitData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      id_number: formData.idNumber,
      house_number: formData.houseNumber,
      gender: formData.gender,
      birthday: formData.dob,
      country: formData.country,
      city: formData.city,
      street: formData.street,
      post_zip: formData.zipCode,
      phone: formData.phone,
    };

    dispatch(updateProfile(submitData));
  };

  const handleChangePassword = () => {
    dispatch(
      updatePassword({
        current_password: formData.currentPassword,
        new_password: formData.newPassword,
      })
    );
  };

  return (
    <Box aria-label="Profile">
      <ContentCard
        title={i18next.t("edit_profile")}
        icon="assets/images/pages/settings/profile.svg"
      >
        <Box className="bg-white rounded-b-[12px] pb-[24px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto">
            <Box
              className={
                isMd
                  ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]"
                  : "p-[16px] block gap-[12px]"
              }
            >
              <InputGroup
                label={i18next.t("First_Name")}
                inputProps={{
                  name: "firstName",
                  value: formData.firstName,
                  onChange: handleChange,
                  readOnly: !enableEdit,
                }}
              />
              <Box className="w-full flex items-center gap-[8px] mt-[5px]">
                <InputGroup
                  label={i18next.t("Last_Name")}
                  inputProps={{
                    name: "lastName",
                    value: formData.lastName,
                    onChange: handleChange,
                    readOnly: !enableEdit,
                  }}
                />
              </Box>
              <Box className="w-full flex sm:items-center gap-[8px] mt-[5px] sm:flex-row flex-col">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">
                  {i18next.t("Phone")}
                </Typography>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  value={formData.phone}
                  onChange={(phone) =>
                    setFormData((prev) => ({ ...prev, phone }))
                  }
                  inputClass="!border-[1.5px] !border-[#D9E1E5] !rounded-[12px] !bg-[#EDF0F2]"
                  buttonClass="!bg-[#EDF0F2] !rounded-[12px] !border-[1.5px] !border-[#D9E1E5]"
                  inputProps={{
                    readOnly: !enableEdit,
                  }}
                />
              </Box>
              <Box className="w-full flex sm:flex-row flex-col sm:items-center gap-[8px] mt-[5px]">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">
                  {i18next.t("DOB")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={formData.dob}
                    onChange={(date) =>
                      setFormData((prev) => ({ ...prev, dob: date }))
                    }
                    renderInput={(params) => <input {...params} />}
                    readOnly={!enableEdit}
                    sx={{
                      backgroundColor: "#EDF0F2",
                      width: "100%",
                      borderRadius: "12px",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "1.5px solid #D9E1E5",
                        borderRadius: "12px",
                      },
                      input: {
                        padding: "8px 12px",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <InputGroup
                label={i18next.t("Email")}
                inputProps={{
                  name: "email",
                  value: formData.email,
                  readOnly: !enableEdit,
                }}
              />
              <Box className="w-full flex sm:items-center sm:flex-row flex-col gap-[8px] mt-[5px] relative">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">
                  {i18next.t("Country")}
                </Typography>
                <Select
                  options={options}
                  value={options.find(
                    (option) => option.label === formData.country
                  )}
                  placeholder={i18next.t("Search_country")}
                  className="custom-select"
                  isSearchable
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, country: val.label }))
                  }
                  isDisabled={!enableEdit}
                />
              </Box>
              <Box className="w-full flex sm:flex-row flex-col sm:items-center gap-[8px] mt-[5px] relative">
                <Typography className="text-[#505155] text-[14px] capitalize min-w-[120px]">
                  {i18next.t("Gender")}
                </Typography>
                <Select
                  options={genderOptions}
                  // value={i18n.t(genderOptions.find((option) => option.label === formData.gender))}
                  value={genderOptions.find(
                    (option) => option.label === formData.gender
                  )}
                  className="custom-select"
                  onChange={(val) => {
                    setFormData((prev) => ({ ...prev, gender: val.label }));
                  }}
                  isDisabled={!enableEdit}
                />
              </Box>
            </Box>
            <Hr />
            <Box
              className={
                isMd
                  ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]"
                  : "p-[16px] block gap-[12px]"
              }
            >
              <InputGroup
                label={i18next.t("User_Name")}
                inputProps={{
                  name: "userName",
                  value: formData.userName,
                  readOnly: !enableEdit,
                }}
              />
              <InputGroup
                label={i18next.t("City")}
                inputProps={{
                  name: "city",
                  value: formData.city,
                  onChange: handleChange,
                  required: true,
                  readOnly: !enableEdit,
                }}
              />
              <InputGroup
                label={i18next.t("ID_Number")}
                inputProps={{
                  name: "idNumber",
                  value: formData.idNumber,
                  onChange: handleChange,
                  required: true,
                  readOnly: !enableEdit,
                  maxLength: 11,
                }}
              />
              <InputGroup
                label={i18next.t("Street")}
                inputProps={{
                  name: "street",
                  value: formData.street,
                  onChange: handleChange,
                  readOnly: !enableEdit,
                }}
              />
              <InputGroup
                label={i18next.t("House_Number")}
                inputProps={{
                  name: "houseNumber",
                  value: formData.houseNumber,
                  onChange: handleChange,
                  readOnly: !enableEdit,
                }}
              />
              <InputGroup
                label={i18next.t("Post_ZIP_code")}
                inputProps={{
                  name: "zipCode",
                  value: formData.zipCode,
                  onChange: handleChange,
                  readOnly: !enableEdit,
                }}
              />
              <Box className="flex justify-end sm:justify-start">
                {enableEdit && (
                  <button
                    className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
                    onClick={handleSave}
                  >
                    {i18n.t("Save")}
                  </button>
                )}
              </Box>
            </Box>
            <Hr />
            <Box
              className={
                isMd
                  ? "p-[16px] grid grid-cols-2 gap-x-[48px] gap-y-[12px]"
                  : "p-[16px] block gap-[12px]"
              }
            >
              <InputGroup
                label={i18next.t("Current_password")}
                inputProps={{
                  name: "currentPassword",
                  type: "password",
                  value: formData.currentPassword,
                  onChange: handleChange,
                  placeholder: i18next.t("Current_password"),
                }}
              />
              <InputGroup
                label={i18next.t("New_password")}
                inputProps={{
                  name: "newPassword",
                  type: "password",
                  value: formData.newPassword,
                  onChange: handleChange,
                  placeholder: i18next.t("New_password"),
                }}
              />
              <Box className="flex justify-end sm:justify-start">
                <button
                  className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
                  onClick={handleChangePassword}
                >
                  {i18next.t("Change_Password")}
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
