import { Badge, Box, Typography, useTheme } from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCustomAuth } from "src/app/hooks/useAuth";
import { useIsLive } from "src/app/hooks/useIsLive";
import { selectTickets } from "src/app/main/store/ticketSlice";
import {
  selectBetslipVisible,
  selectBetType,
  setBetslipVisbile,
  setMobileSignupOpen,
} from "src/app/main/store/uiSlice";
import { BetslipContainer } from "app/shared-components/RightSidebar/BetslipContainer";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM, ONLINE_SYSTEM } from "src/app/constants";
import { MobileUserProfileMenu } from "app/shared-components/UserBox/MobileUserProfileMenu";
import i18n from "src/i18n";
import { selectCurrentLanguageId, selectMenu } from "app/store/i18nSlice";

export const MobileFooter = () => {
  const location = useLocation();
  const sportButtonRef = useRef(null);
  const casinoButtonRef = useRef(null);
  const [sportMenuVisible, setSportMenuVisible] = useState(false);
  const [casinoMenuVisible, setCasinoMenuVisible] = useState(false);
  const [sportButtonWidth, setSportButtonWidth] = useState(0);
  const [casinoButtonWidth, setCasinoButtonWidth] = useState(0);
  const { isLive } = useIsLive();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticated = useCustomAuth();
  const tickets = useSelector(selectTickets);
  const betType = useSelector(selectBetType);
  const betslipVisible = useSelector(selectBetslipVisible);
  const isMd = useScreenMd();
  const theme = useTheme();
  const systemType = useSystemType();
  const [openMobileProfileMenu, setOpenMobileProfileMenu] = useState(false);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const menus = useSelector(selectMenu)
  const [slugs, setSlugs] = useState([]);


  const FooterButton = ({ onClick, icon, title, selected, style, noTitle = false }) => {
    return (
      <button
        className="flex flex-col gap-[8px] justify-center items-center h-[65px] flex-1 hover:bg-[#cbd0d2]"
        onClick={onClick}
        style={style}
      >
        <img src={`${icon.replace(".svg", "")}Selected.svg`} alt="nav-item" style={{ filter: selected ? "" : "grayscale(100%)", height: noTitle && '30px' }} />
        <Typography
          className="text-[12px] text-[#333] font-500 truncate"
          sx={selected ? { color: "#4da533 !important" } : undefined}
        >
          {title}
        </Typography>
      </button>
    );
  };

  const FooterLink = ({ path, onClick, icon, title, selected, style }) => {
    return (
      <Link
        className="flex flex-col gap-[8px] justify-center items-center h-[65px] flex-1 hover:bg-[#cbd0d2]"
        to={path || null}
        onClick={onClick}
        style={style}
      >
        <img src={`${icon.replace(".svg", "")}Selected.svg`} alt="nav-item" style={{ filter: selected ? "" : "grayscale(100%)" }} />
        <Typography
          className="text-[12px] text-[#333] font-500 truncate"
          sx={selected ? { color: "#4da533 !important" } : undefined}
        >
          {title}
        </Typography>
      </Link>
    );
  };

  const onWindowClick = (e) => {
    const sportButton = sportButtonRef.current;
    if (!sportButton) return;
    if (!sportButton.contains(e.target)) {
      setSportMenuVisible(false);
    }
  };

  const handleResize = () => {
    const sportsButton = sportButtonRef.current;
    if (sportsButton) {
      const { width } = sportsButton.getBoundingClientRect();
      setSportButtonWidth(width);
    }
    const casinoButton = casinoButtonRef.current;
    if (casinoButton) {
      const { width } = casinoButton.getBoundingClientRect();
      setCasinoButtonWidth(width);
    }
  };

  const handleMouseDown = (e) => {
    const sportsButton = sportButtonRef.current;
    if (!sportsButton) return;
    if (!sportsButton.contains(e.target)) {
      setSportMenuVisible(false);
    }
    const casinoButton = casinoButtonRef.current;
    if (!casinoButton) return;
    if (!casinoButton.contains(e.target)) {
      setCasinoMenuVisible(false);
    }
  }

  useEffect(() => {
    window.document.addEventListener("mousedown", onWindowClick);
    window.addEventListener("resize", handleResize);
    window.addEventListener('mousedown', handleMouseDown);
    handleResize();
    return () => {
      window.document.removeEventListener("mousedown", onWindowClick);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      const availableMenus = [...menus]
      const slugs = availableMenus.map((menu) => menu.slug);
      setSlugs(slugs);
    }
  }, [menus])

  useEffect(() => {
    dispatch(setBetslipVisbile(false));
  }, [window.location.href]);

  const isSelected = (exp) => {
    return RegExp(exp).test(location.pathname);
  };

  const path = location.pathname;
  const isCasinoGamePath = path.startsWith("/casino/game") || path.startsWith("/live-casino/game") || path.startsWith("/virtual-game/game");

  return (
    <>
      <Box
        aria-label="mobileFooter"
        className="w-full flex md:hidden justify-center items-center fixed left-0 bottom-0 shadow-2xl shadow-black z-[1000]"
        bgcolor={theme.palette.background.mobileFooter || "#edf0f2"}
      >
        <Box className="flex w-full">
          {(!isCasinoGamePath && slugs.includes('home')) && <FooterLink
            title={i18n.t("Home_Page")}
            icon="assets/images/navItemsMobile/home.svg"
            path="/home"
            selected={isSelected("^/home")}
          />}
          {isCasinoGamePath && <FooterButton
            title="Back"
            icon="assets/images/navItemsMobile/home.svg"
            onClick={() => navigate(-1)}
          />}
          {(slugs.includes('sports_betting') || slugs.includes('live')) && (
            <Box className="relative flex-1" ref={sportButtonRef}>
              <FooterButton
                title={i18n.t("Sports")}
                icon="assets/images/navItemsMobile/sports.svg"
                style={{ width: "100%" }}
                onClick={() => setSportMenuVisible((v) => !v)}
                selected={sportMenuVisible || isSelected("^/sports")}
              />
              <Box
                className="absolute top-0 translate-y-[-100%] z-[100] hidden opacity-0 data-[visible=true]:block data-[visible=true]:opacity-100"
                data-visible={sportMenuVisible}
              >
                <Box className="flex flex-col gap-[6px] py-[12px] px-[16px] rounded-[12px] bg-[#4da533]">
                  {slugs.includes('sports_betting') && (
                    <Link
                      className="flex items-center gap-[8px] py-[10px] px-[12px] bg-[#FFFFFF0F] rounded-[6px]"
                      onClick={() => setSportMenuVisible(false)}
                      to="/sports/top?live=true&events=true"
                      data-selected={isSelected("/sports") && !!isLive}
                    >
                      <img src="assets/images/liveSports.svg" className="max-w-none" />
                      <Typography className="text-white text-[14px] font-500">{i18n.t('Live')}</Typography>
                    </Link>
                  )}
                  {slugs.includes('live') && (
                    <Link
                      to="/sports/top?events=true"
                      className="flex items-center gap-[8px] py-[10px] px-[12px] bg-[#FFFFFF0F] rounded-[6px]"
                      onClick={() => setSportMenuVisible(false)}
                      data-selected={isSelected("/sports") && !isLive}
                    >
                      <img src="assets/images/normalSports.svg" className="max-w-none" />
                      <Typography className="text-white text-[14px] font-500">{i18n.t('Sports')}</Typography>
                    </Link>
                  )}
                </Box>
                <Box className="relative top-[-8px] left-[28px]">
                  <img src="assets/images/downTriangle.svg" />
                </Box>
              </Box>
            </Box>
          )}

          <Badge
            className="flex-1"
            color="error"
            // badgeContent={betType === "single" ? tickets.length : "!"}
            badgeContent={tickets.length}
            invisible={tickets.length === 0}
            sx={{
              ".MuiBadge-badge": {
                ml: "12px",
                right: "12px",
                top: "8px",
              },
            }}
          >
            <FooterButton
              title={i18n.t("Betslip")}
              icon="assets/images/navItemsMobile/betslip.svg"
              onClick={() => dispatch(setBetslipVisbile(!betslipVisible))}
              selected={isSelected("^/betslip") || betslipVisible}
              style={{ width: "100%", }}
            // noTitle={systemType === ONLINE_SYSTEM}
            />
          </Badge>
          {(slugs.includes('casino') || slugs.includes('live_casino') || slugs.includes('virtual_games')) && (
            <Box className="relative flex-1" ref={casinoButtonRef}>
              <FooterButton
                title={i18n.t("Casino")}
                icon="assets/images/navItemsMobile/casino.svg"
                style={{ width: "100%" }}
                onClick={() => setCasinoMenuVisible((v) => !v)}
                selected={casinoMenuVisible || isSelected("^/casino") || isSelected("^/live-casino")}
              />
              <Box
                className="absolute top-0 translate-y-[-100%] z-[100] hidden opacity-0 data-[visible=true]:block data-[visible=true]:opacity-100"
                data-visible={casinoMenuVisible}
              >
                <Box
                  className="flex flex-col gap-[6px] py-[12px] px-[16px] rounded-[12px] bg-[#4da533]"
                  sx={{
                    marginLeft: systemType === BETSHOP_SYSTEM && '-90px'
                  }}
                >
                  {slugs.includes('casino') && (
                    <Link
                      className="flex items-center gap-[8px] py-[10px] px-[12px] bg-[#FFFFFF0F] rounded-[6px]"
                      onClick={() => setCasinoMenuVisible(false)}
                      to="/casino/all"
                      data-selected={isSelected("/casino")}
                    >
                      <img src="assets/images/liveSports.svg" className="max-w-none" />
                      <Typography className="text-white text-[14px] font-500">{i18n.t("Casino")}</Typography>
                    </Link>
                  )}
                  {slugs.includes('live_casino') && (
                    <Link
                      to="/live-casino/all"
                      className="flex items-center gap-[8px] py-[10px] px-[12px] bg-[#FFFFFF0F] rounded-[6px]"
                      onClick={() => setCasinoMenuVisible(false)}
                      data-selected={isSelected("/live-casino")}
                    >
                      <img src="assets/images/normalSports.svg" className="max-w-none" />
                      <Typography className="text-white text-[14px] font-500">{i18n.t("Live_Casino")}</Typography>
                    </Link>
                  )}
                  {slugs.includes('virtual_games') && (
                    <Link
                      to="/virtual-games/all"
                      className="flex items-center gap-[8px] py-[10px] px-[12px] bg-[#FFFFFF0F] rounded-[6px]"
                      onClick={() => setCasinoMenuVisible(false)}
                      data-selected={isSelected("/virtual-games")}
                    >
                      <img src="assets/images/normalSports.svg" className="max-w-none" />
                      <Typography className="text-white text-[14px] font-500">{i18n.t("Virtual")}</Typography>
                    </Link>
                  )}
                </Box>
                <Box className="relative top-[-8px] left-[28px]">
                  <img src="assets/images/downTriangle.svg" />
                </Box>
              </Box>
            </Box>
          )}

          {!authenticated && systemType === ONLINE_SYSTEM && (
            <FooterButton
              title={i18n.t("Sign_Up")}
              icon="assets/images/navItemsMobile/signup.svg"
              onClick={() => dispatch(setMobileSignupOpen(true))}
            />
          )}
          {authenticated && (systemType === ONLINE_SYSTEM || systemType === BETSHOP_SYSTEM) && (
            <FooterButton
              title={i18n.t("Profile")}
              icon="assets/images/navItemsMobile/signup.svg"
              onClick={() => setOpenMobileProfileMenu(true)}
            />
          )}
        </Box>
      </Box>
      {(!isMd && betslipVisible) && (
        <Box
          className="absolute w-full h-full left-0 top-1/3 opacity-0 invisible pt-[69px] pb-[65px] z-[800] data-[visible]:top-0 data-[visible]:opacity-100 data-[visible]:visible transition-all duration-150 ease-in-out"
          data-visible={betslipVisible || null}
        >
          <Box className="h-full px-[8px] pt-[8px] bg-white overflow-auto overflow-x-hidden">
            <BetslipContainer />
          </Box>
        </Box>
      )}
      <MobileUserProfileMenu
        open={openMobileProfileMenu}
        handleClose={() => setOpenMobileProfileMenu(false)}
      />
    </>
  );
};
