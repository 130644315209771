import { memo, useState } from "react";
import FooterText from "app/theme-layouts/shared-components/CopyrightText";
import FooterLogos from "app/theme-layouts/shared-components/FooterLogos";
import FooterPaymentLogos from "app/theme-layouts/shared-components/FooterPaymentLogos";
import FooterMenu from "app/theme-layouts/shared-components/FooterMenu";
import LogoNew from "app/shared-components/LogoNew";
import { Stack, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSystem } from "src/app/main/store/systemSettingsSlice";
import { useEffect } from "react";
import { Typography} from "@mui/material";
import { useSystemType } from "src/app/hooks/useSystemType";

function FooterLayout1() {
  const systemSettings = useSelector(selectSystem);
  const navigate = useNavigate();
  const theme = useTheme();
  const [isBetinDomain, setIsBetinDomain] = useState(false);
  
  useEffect(() => {
    const isBetinDomain = window.location.hostname === "betin.network" || window.location.hostname === "betin90.network" ? true : false;
    setIsBetinDomain(isBetinDomain)
  }, [])

  console.log(isBetinDomain, 'isBetinDomain')

  const { facebook, twitter, instagram, tiktok } = systemSettings;

  useEffect(() => {
    console.log('system-setting')
    if (!systemSettings) {
      console.log('no-systemSetting')
      navigate('404')
    }
  }, [systemSettings])

  return (
    <footer aria-label="FooterLayout1"
      className="bottom-0 w-full py-[40px] mt-[12px] flex flex-col md:gap-[55px] gap-[24px] pb-[35%] md:pb-[40px]"
      style={{
        borderTop: `1px solid ${theme.palette.background.dark600}`,
        background: theme.palette.background.dark700
      }}
    >
      {!isBetinDomain && <>
        <FooterMenu />
      </>}
      <FooterLogos />
      <Stack
        justifyContent={'center'}
        direction="row"
        gap="16px"
        px={{ xs: "24px", md: 0 }}
      >
        <Link to={facebook} className="w-[30px] h-[30px]">
          <img src={"assets/images/social-media/facebook.png"} alt="" />
        </Link>
        <Link to={twitter} className="w-[30px] h-[30px]">
          <img src={"assets/images/social-media/twitter.png"} alt="" />
        </Link>
        <Link to={instagram} className="w-[30px] h-[30px]">
          <img src={"assets/images/social-media/instagram.png"} alt="" />
        </Link>
        <Link to={tiktok} className="w-[30px] h-[30px]">
          <img src={"assets/images/social-media/tiktok.png"} alt="" />
        </Link>
      </Stack>

      {isBetinDomain && <Typography
        className="text-[#A8B3CF] text-[14px] md:text-[16px] mt-[10px]"
        gutterBottom
      >
        © 2025 Betin.Network | All Rights Reserved
      </Typography>}   
      
      <FooterText />
      
    </footer>
  );
}

export default memo(FooterLayout1);