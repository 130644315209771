import { Navigate } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/sign-in/SignInConfig";
import ResetPasswordConfig from "../main/forgot-password/ResetPasswordConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import ExampleConfig from "../main/example/ExampleConfig";
import DashboardConfig from "../main/dashboard/DashboardConfig";
import SportsConfig from "../main/sports/SportsConfig";
import CasinoConfig from "../main/casino/CasinoConfig";
import SettingsConfig from "../main/settings/SettingsConfig";
import PromotionConfig from "../main/promotions/PromotionConfig";
import BetslipConfig from "../main/betslip/BetslipConfig";
import InfomrationConfig from "../main/information/InformationConfig";
import AffiliateConfig from "../main/affiliate/AffiliateConfig";
import TokenDashboardConfig from "../main/token-dashboard/TokenDashboardConfig";

const routeConfigs = [
  ExampleConfig,
  DashboardConfig,
  SignOutConfig,
  SignInConfig,
  ResetPasswordConfig,
  SignUpConfig,
  SportsConfig,
  CasinoConfig,
  SettingsConfig,
  PromotionConfig,
  BetslipConfig,
  InfomrationConfig,
  AffiliateConfig,
  TokenDashboardConfig
];

const defaultPath = (window.location.hostname === "betin.network" || window.location.hostname === "betin90.network") ? "/dashboard" : "/home";

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  // {
  //   path: '/',
  //   element: <Navigate to="/example" />,
  //   auth: settingsConfig.defaultAuth,
  // },
  {
    path: "/",
    element: <Navigate to={defaultPath} />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
    // element: <Navigate to="/home" />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
