import { Box, Checkbox, FormControlLabel, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useModal } from "src/app/hooks/useModal";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencies } from "app/store/i18nSlice";
import Select from "react-select";
import { BonusItem, bonusItemsData } from "./BonusItem";
import JwtService from "src/app/auth/services/jwtService";
import { ContactlessOutlined } from "@mui/icons-material";
import { getBonusList, selectAffiliateId } from "src/app/main/store/uiSlice";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const options = countryList().getData();

const currency = [
  { label: "USD" },
  { label: "TRY" },
  { label: "ARS" },
  { label: "THB" },
  { label: "VES" },
  { label: "BRL" },
];

const inputStepOne = {
  phone: "",
  username: "",
  email: "",
  password: "",
  country: "",
  currency: "",
};

export const MobileSignupModal = ({ open, onClose }) => {
  const [inputData, setInputDataT] = useState(inputStepOne);
  const [inputError, setInputErrorT] = useState({});
  const [extraError, setExtraError] = useState(null);
  const [isFirst, setIsFirst] = useState(true);
  const currencyData = useSelector(selectCurrencies);
  const [bonusMode, setBonusMode] = useState(null);
  const [step, setStep] = useState("register");
  const [bonusList, setBonusList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const currentLanguageId = useSelector(selectCurrentLanguageId); 
  const affiliateId = useSelector(selectAffiliateId);

  const { username, password, phone, email, country, currency } = inputData;

  useModal(open);

  const setInputData = (d) => setInputDataT((data) => ({ ...data, ...d }));
  const setInputError = (d) => setInputErrorT((data) => ({ ...data, ...d }));

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setInputDataT(inputStepOne);
    setIsFirst(true);
    setInputErrorT({});
    setExtraError(null);
    setBonusMode(null);
  }, [open]);

  useEffect(() => {
    if (isFirst) return;
    if (username.length === 0) setInputError({ username: "Username required" });
    else setInputError({ username: null });
  }, [username, isFirst]);

  useEffect(() => {
    if (isFirst) return;
    if (email.length === 0) setInputError({ email: "Email required" });
    else if (!email.includes("@")) setInputError({ email: "Email must contains @" });
    else setInputError({ email: null });
  }, [email, isFirst]);

  useEffect(() => {
    if (isFirst) return;
    if (password.length === 0) setInputError({ password: "Password required" });
    else setInputError({ password: null });
  }, [password, isFirst]);

  const handleCaptcha = (token) => {};

  const handleRegister = (e) => {
    e.preventDefault();
    if (step === "register") {
      setIsFirst(false);

      JwtService.createUser({
        username,
        email,
        password,
        phone,
        country: country,
        currency: currency,
        affiliate_id: affiliateId
      })
        .then((user) => {
          // No need to do anything, registered user data will be set at app/auth/AuthContext
        })
        .catch((_errors) => {
          setExtraError(_errors.response.data.user_error);
        });
    }
  };

  const handleButtonClick = () => {
    if (step === "bonus") {
      setStep("register");
    } else if (step === "register") {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="fixed w-screen h-[calc(var(--app-height))] left-0 top-0 overflow-y-auto" aria-label="MobileLoginModal">
      <Box className="relative w-full min-h-full bg-white pb-[50%]">
        <Box className="absolute right-[12px] top-[12px] z-[100]">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <img
          className="w-full h-[200px] object-cover object-center relative block"
          src="/assets/images/signup.jpg"
          alt=""
        />
        <Box className="header_button w-full pt-[30px] px-[14px]" aria-label="modal-body">
          <form className="px-[16px] py-[12px] w-full" onSubmit={handleRegister}>
              <>
                <Box className="form_group">
                  <Box className="one-step-registration-form_row__L2C3i">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setInputData({ phone })}
                    />
                  </Box>
                </Box>
                <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]" />
                <Box className="form_group">
                  <input
                    placeholder={i18n.t("Username")}
                    type="text"
                    className="form-input__input !pl-[16px]"
                    value={username}
                    onChange={(e) => setInputData({ username: e.target.value })}
                  />
                </Box>
                <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                  {inputError["username"] && inputError["username"]}
                </Box>
                <Box className="form_group">
                  <input
                    placeholder={i18n.t("Email")}
                    type="email"
                    className="form-input__input !pl-[16px]"
                    value={email}
                    onChange={(e) => setInputData({ email: e.target.value })}
                  />
                </Box>
                <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                  {inputError["email"] && inputError["email"]}
                </Box>
                <Box className="relative form_group">
                  <input
                    placeholder={i18n.t("Password")}
                    type={showPassword ? "text" : "password"}
                    className="form-input__input !pl-[16px]"
                    value={password}
                    onChange={(e) => setInputData({ password: e.target.value })}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute right-[12px] top-[50%] transform -translate-y-1/2 !text-[16px] cursor-pointer text-gray-500"
                    onClick={togglePasswordVisibility}
                  />
                </Box>
                <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                  {inputError["password"] && inputError["password"]}
                </Box>
                <Box className="form_group country_currency">
                  <Box className="form-input">
                    <Select
                      options={options}
                      placeholder={i18n.t("Search_country")}
                      isSearchable
                      onChange={(val) => setInputData({ country: val.label })}
                    />
                  </Box>
                  <Box className="form-input currency">
                    <Select
                      options={currencyData.map((c) => ({ label: c }))}
                      placeholder={i18n.t("Currency")}
                      isSearchable
                      onChange={(val) => setInputData({ currency: val.label })}
                    />
                  </Box>
                </Box>
                <FormControlLabel
                  control={<Checkbox defaultChecked sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />}
                  label={i18n.t("I_wish_to_receive_promotional_offers")}
                  sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                />
                <Box aria-label="error-message" className="text-red min-h-[20px]">
                  {extraError && extraError}
                </Box>
              </>
            <Box className="flex flex-col justify-center items-center mt-[20px] gap-[12px] w-full">
                <>
                  <button
                    type="submit"
                    className="py-[12px] bg-green-700 rounded-full min-w-[300px] w-full text-white uppercase hover:bg-green-900 disabled:opacity-60"
                  >
                    {i18n.t("Registration")}
                  </button>
                </>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};