// components/SvgImage.tsx

const DynamicImage = ({
  src,
  className = "",
}) => {
  return (
    <div 
      className={className}
      style={{ maskImage: `url(${src})`, WebkitMaskImage: `url(${src})`, maskSize: 'contain', WebkitMaskSize: 'contain', maskRepeat: 'no-repeat', WebkitMaskRepeat: 'no-repeat', backgroundColor: 'currentColor' }}
    />
  );
};

export default DynamicImage;