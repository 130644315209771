import { Box } from "@mui/material";
import { PromotionCategoryLink } from "./components/PromotionCategoryLink/PromotionCategoryLink";
import { Outlet, useLocation } from "react-router-dom";
import FooterLayout1 from "app/theme-layouts/layout1/components/FooterLayout1";
import { HorizontalScroll } from "app/shared-components/HorizontalScroll";
import { useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import i18n from "src/i18n";


export const Promotion = () => {
  const location = useLocation();
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const categoryList = [
    {
      id: "all",
      title: i18n.t("All"),
      icon: "assets/images/navItems/more.svg",
      path: "/promotions/all",
    },
    {
      id: "popular",
      title: i18n.t("Popular"),
      icon: "assets/images/pages/casino/popular.svg",
      path: "/promotions/popular",
    },
    {
      id: "welcome",
      title: i18n.t("Welcome"),
      icon: "assets/images/pages/casino/all.svg",
      path: "/promotions/welcome",
    },
    {
      id: "sports",
      title: i18n.t("Sports"),
      icon: "assets/images/navItems/sports.svg",
      path: "/promotions/sports",
    },
    {
      id: "casino",
      title: i18n.t("Casino"),
      icon: "assets/images/navItems/casino.svg",
      path: "/promotions/casino",
    },
    {
      id: "poker",
      title: i18n.t("Poker"),
      icon: "assets/images/pages/casino/poker.svg",
      path: "/promotions/poker",
    },
    {
      id: "games",
      title: i18n.t("Live_Sports"),
      icon: "assets/images/pages/casino/new.svg",
      // path: "/promotions/live-sports",
      path: "/promotions/games",
    },

  ];


  return (
    <Box aria-label="Promotions" className="max-h-[calc(100vh)] overflow-y-auto flex flex-col justify-between h-full sm:mt-10">
      <Box className="px-[8px]">
        <HorizontalScroll>
          <Box className="flex gap-[4px] mt-[8px] sm:w-full sm:justify-center">
            {categoryList.map((category) => (
              <PromotionCategoryLink key={category.id} {...category} selected={location.pathname === category.path} />
            ))}
          </Box>
        </HorizontalScroll>
      </Box>
      <Outlet />
      <Box className="pb-[10%] md:pb-[5%]">
        <FooterLayout1 />
      </Box>
    </Box>
  );
};
