import Casino from "./Casino";
import { CasinoGame } from "./components/CasinoGame";

const paths = ["casino", "live-casino", "virtual-games"];

const casinoConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    ...paths.map((path) => ({
      path: `${path}/:casinoTypeId`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map((path) => ({
      path: `${path}/detail/:casinoTypeId`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map((path) => ({
      path: `${path}/vendor/:vendorId`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map((path) => ({
      path: `${path}/favorites`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map((path) => ({
      path: `${path}/popular`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map((path) => ({
      path: `${path}/jackpot`,
      element: <Casino key={path} rootPath={path} />,
    })),
    ...paths.map(path => ({
      path: `${path}/demo/:gameId`,
      element: <CasinoGame key={path} rootPath={path} demo />,
    })),
    ...paths.map((path) => ({
      path: `${path}/game/:gameId`,
      element: <CasinoGame key={path} rootPath={path} />,
    })),
  ],
};

export default casinoConfig;
