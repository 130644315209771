import { Box } from "@mui/material";
import { ContentCard } from "../components/ContentCard";
import { useRef } from "react";
import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useState } from "react";

export const InformationData = () => {
  const dataRef = useRef();
  const { data } = useOutletContext();
  const { contentId } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const dataElement = dataRef.current;
    if (dataElement) {
      const item = data.find(item => item.id === contentId);
      setItem(item);
      dataElement.innerHTML = item?.content || "No data";
    }
  }, [contentId, data]);

  return (
    <ContentCard title={item?.title || ""}>
      <Box className="bg-white rounded-b-[12px] p-[24px] pb-[10%] flex-grow h-full overflow-y-auto" ref={dataRef}></Box>
    </ContentCard>
  );
};
