// src/utils/SiteMetadataManager.js

const SITE_CONFIGS = {
  'bigbet2.com': {
    faviconPath: '/assets/icons/favicon-bigbet.png',
    metaDescription: 'Online Sports Betting & Casino'
  },
  'dev.bigbet2.com': {
    faviconPath: '/assets/icons/favicon-bigbet.png',
    metaDescription: 'Online Sports Betting & Casino'
  },
  'megawin200.com': {
    faviconPath: '/assets/icons/favicon-megawin.png',
    metaDescription: 'Güvenilir, Online Bahis ve Casino Sitesi.'
  },
  'dev.megawin200.com': {
    faviconPath: '/assets/icons/favicon-megawin.png',
    metaDescription: 'Güvenilir, Online Bahis ve Casino Sitesi.'
  }
};

export const updateSiteMetadata = () => {
  const domain = window.location.hostname;
  const config = SITE_CONFIGS[domain];

  if (!config) {
    console.warn(`No configuration found for domain: ${domain}`);
    return;
  }

  // Update favicon
  const link = document.querySelector("link[rel='shortcut icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = config.faviconPath;
  document.head.appendChild(link);

  // Create and add meta description
  const metaDescription = document.createElement('meta');
  metaDescription.name = 'description';
  metaDescription.content = config.metaDescription;

  document.head.appendChild(metaDescription);
};

// Usage example:
// Import in your app's entry point and call:
// updateSiteMetadata();