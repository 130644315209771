import { Box, useTheme } from "@mui/material";

export const CardBox = ({ header, children }) => {
  const theme = useTheme();
  const cardHeader = theme.palette.cardHeader;
  
  return (
    <Box aria-label="cardBox" className="flex flex-col min-w-[310px]">
      <Box
        className="rounded-t-[12px] flex items-center text-[16px] uppercase font-500 px-[16px] min-h-[40px]"
        bgcolor={cardHeader ? cardHeader.main : "#1a3769"}
        color={cardHeader ? cardHeader.text : "white"}
      >
        {header}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
