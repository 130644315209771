import React from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";

function FooterLogos() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const systemType = useSystemType();

  const isBetinDomain =
    window.location.hostname === "betin.network" ? true : false;

  let logos = [
    { src: "assets/images/FooterLogos/Bitcoin.svg", alt: "Bitcoin" },
    { src: "assets/images/FooterLogos/Dogecoin.svg", alt: "Dogecoin" },
    { src: "assets/images/FooterLogos/zero.png", alt: "zero" },
    { src: "assets/images/FooterLogos/etherium2.svg", alt: "Ethereum" },
    { src: "assets/images/FooterLogos/tron2.svg", alt: "Tron" },
    { src: "assets/images/FooterLogos/ripple2.svg", alt: "Ripple" },
    { src: "assets/images/FooterLogos/banxa2.svg", alt: "Banxa" },
    { src: "assets/images/FooterLogos/wert2.svg", alt: "Wert" },
    { src: "assets/images/FooterLogos/moonpay2.svg", alt: "Moonpay" },
  ];

  if (isBetinDomain) {
    logos = [
      {
        src: "assets/images/FooterLogos/binance_smart_chain.svg",
        alt: "Binance",
      },
      { src: "assets/images/FooterLogos/a0_logo.svg", alt: "ALEPH ZERO" },
    ];
  }

  return (
    // Desktop View: Static Grid
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="md:px-[24px] md:gap-[58px] md:py-[8px] py-[16px] flex-wrap justify-center gap-[24px] px-[8px]"
      sx={
        {
          // background: theme.palette.background.dark600,
        }
      }
    >
      {systemType === ONLINE_SYSTEM && (
        <a href="#" className="logo-link">
          <img
            src={'assets/images/logo/meganetgaminglogo.png'}
            alt={'meganet logo'}
            style={{
              maxWidth: "150px", // Control logo size
              height: "auto",
            }}
          />
        </a>
      )}
      {logos.map((logo, index) => (
        <a href="#" key={index} className="logo-link">
          <img
            src={logo.src}
            alt={logo.alt}
            style={{
              maxWidth: logo.alt === 'zero' ? "100px" : "150px", // Control logo size
              height: "auto",
            }}
          />
        </a>
      ))}
    </Stack>
  );
}

export default FooterLogos;
